.App {
  background-color: #c4c4c4;
  font-family: 'Helvetica Neue', sans-serif;
}

.App-header {
  padding-top: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
}

.label {
  font-weight: bold;
  padding-left: 10px;
}

.button {
  z-index: 2;
  cursor: pointer;
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 10px 0;
}

.cropped-image {
  height: 600px;
  width: auto;
}

.cropped-image-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

._coverImage-holder {
  padding: 25px 40px;
  background-color: black;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.crop-container {
  position: relative;
  height: 480px;
  width: 100%;
}

.controls {
  display: flex;
  flex-direction: column;
  width: 600px;
  position: absolute;
  bottom: -15px;
}
